import { Injectable } from '@angular/core';
import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { SecurityService } from '../security/security.service';
import { environment } from 'src/environments/environment';
import { IMessage } from '@stomp/stompjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private rxStompConfigFactory(): InjectableRxStompConfig {
    return {
      // Which server?
      brokerURL: environment.sensor_websocket_url,

      // brokerURL: socketProvider(),

      // Headers
      // Typical keys: login, passcode, host

      connectHeaders: { authorization: this.securityService.getToken() },

      // How often to heartbeat?
      // Interval in milliseconds, set to 0 to disable
      heartbeatIncoming: 0, // Typical value 0 - disabled
      heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

      // Wait in milliseconds before attempting auto reconnect
      // Set to 0 to disable
      // Typical value 500 (500 milli seconds)
      reconnectDelay: 5000,

      // Will log diagnostics on console
      // It can be quite verbose, not recommended in production
      // Skip this key to stop logging to console
      /*
      debug: (msg: string): void => {
        console.log(new Date(), msg);
      }
      */
    };
  }


  constructor(private rxStompService: RxStompService, private securityService: SecurityService) {
    if (securityService._isLoggedIn) {
      // If user already logged in the time of class initialization
      this.activateStompService();
    }

    securityService.userEvent().subscribe(event => {
      if (event === 'login') {
        this.activateStompService();
      } else if (event === 'logout') {
        this.deactivateStompService();
      }
    });

  }

  public subscribeTopic(topic: string): Observable<IMessage> {
    return this.rxStompService.watch(topic);
  }

  private activateStompService() {
    if (this.rxStompService.active) {
      this.deactivateStompService();
    }
    this.rxStompService.configure(this.rxStompConfigFactory());
    this.rxStompService.activate();
  }

  private deactivateStompService() {
    this.rxStompService.deactivate();
  }
}
