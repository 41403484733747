<mat-toolbar color="primary">
  <mat-toolbar-row>
    <mat-icon svgIcon="metric_greenhouse"></mat-icon>
    <span class="app-name">SenseStat</span>
    <span class="toolbar-fill-remaining-space"></span>

    <div *ngIf="isAuthenticated()">
      <button mat-icon-button routerLink="/dashboard">
        <mat-icon>dashboard</mat-icon>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>settings</mat-icon>
      </button>

      <mat-menu #menu="matMenu">


        <button routerLink="/dashboard-settings" mat-menu-item>
          <mat-icon>dashboard</mat-icon>
          <span>Dashboard settings</span>
        </button>

        <button routerLink="/userlist" mat-menu-item>
          <mat-icon>people</mat-icon>
          <span>Users</span>
        </button>

        <button routerLink="/change-password" mat-menu-item>
          <mat-icon>lock</mat-icon>
          <span>Change password</span>
        </button>

      </mat-menu>

      <button mat-icon-button (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>