<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>
      {{assemblyName | async}}
      <!-- 
        <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item>Expand</button>
          <button mat-menu-item>Remove</button>
        </mat-menu>
        -->
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="dashboard-card-content">
    <div class="metric-container">
      <!-- Component changed! update needed!
      <app-sensor-metric-display class="metric-horizontal-container" metricIcon="metric_soil_moisture"
        metricValue="{{currentMoistureMetric}}" metricUnit="{{moistureUnit}}">
      </app-sensor-metric-display>
    -->
      <mat-icon svgIcon="metric_irrigation" class="mat-icon-large"
        [ngClass]="(switchState=='1')?'switch-on':'switch-off'"></mat-icon>
      <mat-icon class="mat-icon-large" [ngClass]="(manualState=='1')?'manual-on':'manual-off'">pan_tool</mat-icon>
    </div>
    <div class="metric-container">
      <mat-slide-toggle class="example-margin" [color]="manualSlideToggleColor" [checked]="manualCommand"
        (change)="eventManualToggle($event)">Manual</mat-slide-toggle>
    </div>
    <div *ngIf="manualCommand" class="metric-container" fxLayout="row" fxLayout.sx="column" fxLayoutGap="5%"
      fxLayoutAlign="space-between">
      <form class="my-form" [formGroup]="manualIrrigationForm">
        <mat-form-field fxFlex="25%">
          <mat-label>Qty(L)</mat-label>
          <input matInput type="number" formControlName="waterQuantity" maxlength="2" placeholder="Water in Liters">
          <mat-hint align="start"><strong>1L - 30L</strong> </mat-hint>
        </mat-form-field>

      </form>
      <button mat-icon-button aria-label="Start" color="warn" (click)="manualWatering(manualWaterQuantity)">
        <mat-icon>double_arrow</mat-icon>
      </button>
    </div>
    <div *ngIf="!manualCommand" class="metric-container">
      <form class="my-form" [formGroup]="autoIrrigationForm">
        <div fxLayout="row" fxLayout.sx="column" fxLayoutGap="3%" fxLayoutAlign="start">
          <mat-form-field fxFlex="25%">
            <mat-label>Qty(L)</mat-label>
            <input matInput formControlName="waterQuantity" placeholder="Liters">
            <mat-hint align="start"><strong>1L - 30L</strong> </mat-hint>
          </mat-form-field>

          <mat-form-field fxFlex="25%">
            <mat-label>Max(S)</mat-label>
            <input matInput formControlName="irrigationTimeoutSec" placeholder="Seconds">
          </mat-form-field>

          <mat-form-field fxFlex="25%">
            <mat-label>Wait(S)</mat-label>
            <input matInput formControlName="irrigationNextRunTimeSpanSec" placeholder="Seconds">
          </mat-form-field>

          <button mat-icon-button aria-label="Start" color="warn" (click)="manualWatering(manualWaterQuantity)">
            <mat-icon>double_arrow</mat-icon>
          </button>

        </div>

        <div fxLayout="row" fxLayout.sx="column" fxLayoutGap="3%" fxLayoutAlign="start">
          <mat-form-field fxFlex="25%">
            <mat-label>Moist(%)</mat-label>
            <input matInput formControlName="minimumMoisture" placeholder="Liters">
            <mat-hint align="start"><strong>0 - 100%</strong> </mat-hint>
          </mat-form-field>

          <mat-form-field fxFlex="25%">
            <mat-label>Retry(S)</mat-label>
            <input matInput formControlName="irrigationRetryRunTimeSpanSec" placeholder="Seconds">
          </mat-form-field>

          <mat-form-field fxFlex="25%">
            <mat-label>Note(S)</mat-label>
            <input matInput formControlName="irrigationNotificationPeriodSec" placeholder="Seconds">
          </mat-form-field>

        </div>

        <div *ngIf="waitingForAssemblyQuery" class="metric-container">
          <h3>Waiting for Query...</h3>
        </div>

      </form>
    </div>

    <div class="metric-footer-container">
      <mat-icon class="metric-footer-icon">update</mat-icon>
      <div class="metric-footer-text">{{lastUpdate}}</div>
    </div>
  </mat-card-content>
</mat-card>