<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>
      {{assemblyName | async}}
      <!-- 
      <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item>Expand</button>
        <button mat-menu-item>Remove</button>
      </mat-menu>
      -->
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="dashboard-card-content">
    <div class="metric-container">
      <mat-icon svgIcon="metric_temperature" class="metric-icon"></mat-icon>
      <span class="metric-value">{{currentTempMetric}}</span>
      <span class="metric-unit">{{tempUnit}}</span>
    </div>
    <div class="metric-container">
      <mat-icon svgIcon="metric_humidity" class="metric-icon"></mat-icon>
      <span class="metric-value">{{currentHumidityMetric}}</span>
      <span class="metric-unit">{{humidityUnit}}</span>
    </div>
    <div class="metric-footer-container">
      <!-- 
      <mat-icon class="metric-footer-icon">update</mat-icon>
      -->
        <div class="metric-footer-text">{{lastUpdateDiff}}</div>        
    </div>
  </mat-card-content>
</mat-card>