import { formatDate, JsonPipe } from '@angular/common';
import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SensorEvent } from '../../model/sensor-event.model';
import { SensorMetric } from '../../model/sensor-metric.model';
import { SensorService } from '../../sensor.service';

@Component({
  selector: 'app-irrigation-widget',
  templateUrl: './irrigation-widget.component.html',
  styleUrls: ['./irrigation-widget.component.scss']
})
export class IrrigationWidgetComponent implements OnInit {
  /* UI Flow
     Startup: Query assembly status, wait for status update, with refresh possibility
     Manual/auto change: Send manual/auto command, wait for status update, with refresh possibility
     Auto state, param update: Send SetConfigParam command, wait for status update, with refresh possibility
     Manual irrigation: Send irrigation command, set irrigation mode
     Irrigation mode: wait for irrigation status updates, display metrics, wait for completion, with refresh and interruption possibility
  */

  autoIrrigationForm: FormGroup;
  manualIrrigationForm: FormGroup;

  assemblyCode: string;
  assemblyName: Subject<string> = new BehaviorSubject('Init...');

  tempMetric: Observable<SensorMetric>;
  currentMoistureMetric: string;
  moistureUnit: string;

  switchCode: string;
  switchEvent: Observable<SensorEvent>;
  switchState: string;

  manualState: string;
  manualCommand = false;
  manualSlideToggleColor: ThemePalette = 'warn';
  manualWaterQuantity: number;



  data: any;
  dataChange: Observable<any>;
  waitingForAssemblyQuery = true;
  waitingForManualAutoSwitching = false;
  lastUpdate: string;

  public setData(data: any): void {
    this.data = data;
    if (data) {
      this.assemblyCode = data.assemblyCode;
      this.switchCode = data.switchCode;
      this.assemblyName.next(data.assemblyName || 'Unknown');
      this.loadLastMetrics();
      this.subscribeOnMetric(this.assemblyCode);
      this.subscribeOnEvents();
      console.log('IrrigationWidget:' + JSON.stringify(data));
    }
  }

  constructor(private formBuilder: FormBuilder, private sensorService: SensorService, @Inject(LOCALE_ID) public locale: string) {
    this.moistureUnit = '%';
    this.dataChange = of(this.data);
  }

  subscribeOnMetric(assemblyCode: string) {
    if (assemblyCode) {
      this.tempMetric = this.sensorService.metricStream.pipe(
        filter(evt => { if (evt.assemblyCode === assemblyCode) { return true; } else { return false; } })
      ) as Observable<SensorMetric>;
    } else { this.assemblyName.next('Unknown'); }

    if (this.tempMetric) {
      this.tempMetric.subscribe((metric: SensorMetric) => {
        if (metric.metricCode === 'MOISTURE') {
          this.currentMoistureMetric = metric.metricValue.toFixed(0);
        }
        this.lastUpdate = formatDate(metric.timestamp, 'yy.MM.dd HH:mm', this.locale);
      });
    } else { this.currentMoistureMetric = '-99'; }
  }

  subscribeOnEvents() {
    if (this.assemblyCode) {
      this.switchEvent = this.sensorService.eventStream.pipe(
        filter(evt => {
          if (evt.assemblyCode === this.assemblyCode
            && (evt.deviceCode === this.switchCode || evt.deviceCode === 'PROC')) { return true; } else { return false; }
        })
      ) as Observable<SensorEvent>;
    }


    if (this.switchEvent) {
      this.switchEvent.subscribe((se: SensorEvent) => {
        console.log('Event:' + se.assemblyCode + '#' + se.deviceCode + ':' + se.event);
        if (se.event === 'SwitchStateChanged' && se.deviceCode === this.switchCode) {
          this.setSwitchState(se.message);
        } else if (se.event === 'ManualOverride' && se.deviceCode === this.switchCode) {
          this.setManualState(se.message);
        } else if (se.event === 'ControlParams') {
          console.log('Pretty:' + JSON.stringify(se.message));
          this.autoIrrigationForm.patchValue(JSON.parse(se.message));
          this.waitingForAssemblyQuery = false;
        }
      });
    }
    // console.log('Log:' + this.assemblyCode + '#' + this.switchCode + ' subscribed');
  }

  setSwitchState(state: string) {
    console.log('setSwitchState:' + state);
    this.switchState = state;
  }

  setManualState(state: string) {
    this.manualCommand = (state === '1' ? true : false);
    this.manualState = state;
  }

  eventManualToggle(e: MatSlideToggleChange) {
    // Command to the irrigation controller
    this.waitingForManualAutoSwitching = true;
    if (e.checked) {
      console.log('IrrigationWidget: Command -> Manual ON');
      this.sensorService.publishCommand(this.assemblyCode, 'PROC', 'Manual ON').subscribe();
    } else {
      console.log('IrrigationWidget: Command -> Auto');
      this.sensorService.publishCommand(this.assemblyCode, 'PROC', 'Auto').subscribe();
    }
    // To be removed
    this.manualCommand = e.checked;
  }

  manualWatering(waterQuantity: number) {
    console.log('manualWatering: Qty -> ' + waterQuantity);
  }


  loadLastMetrics() {
    // const dateFrom: Date = new Date();
    // dateFrom.setDate(dateFrom.getDate() - 5);
    this.sensorService.getLastMetrics(this.assemblyCode).subscribe((data) => {
      data.result
        .forEach((metric) => {
          if (metric.metricCode === 'MOISTURE') {
            this.currentMoistureMetric = this.currentMoistureMetric || metric.metricValue.toFixed(0);
          }
          this.lastUpdate = this.lastUpdate || formatDate(metric.timestamp, 'yy.MM.dd HH:mm', this.locale);
        });
    });
  }

  loadParameters() {
    this.waitingForAssemblyQuery = true;
    this.sensorService.publishCommand(this.assemblyCode, 'PROC', 'getControlParams').subscribe();
  }

  ngOnInit(): void {
    this.autoIrrigationForm = this.formBuilder.group({
      waterQuantity: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      irrigationTimeoutSec: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      irrigationNextRunTimeSpanSec: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      minimumMoisture: [''],
      irrigationRetryRunTimeSpanSec: [''],
      irrigationNotificationPeriodSec: ['']
    });

    this.manualIrrigationForm = this.formBuilder.group({
      waterQuantity: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])]
    });
  }

}
