import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { filter, last } from 'rxjs/operators';
import { SensorEvent } from '../model/sensor-event.model';
import { SensorService } from '../sensor.service';

@Component({
  selector: 'app-sensor-switch',
  templateUrl: './sensor-switch.component.html',
  styleUrls: ['./sensor-switch.component.scss']
})
export class SensorSwitchComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;
  @Input() assemblyCode: string;
  @Input() deviceCode: string;
  @Input() config: any;

  switchEvent: Observable<SensorEvent>;

  switchState: string;
  manualState: string;
  lastUpdate: Date;
  lastUpdateDiff: string = "...";

  data: any;

  secondsCounter = interval(1000 * 10);
  // Subscribe to begin publishing values

  subscription = this.secondsCounter.subscribe(n => {
    this.refreshLastUpdate();
  }  );

  constructor(private sensorService: SensorService,
    @Inject(LOCALE_ID) public locale: string) {
    this.config = this.config || { on: {}, auto: {}, off: {} };
  }


  subscribeOnEvents() {
    if (this.assemblyCode) {
      this.switchEvent = this.sensorService.eventStream.pipe(
        filter(evt => {
          if (evt.assemblyCode === this.assemblyCode && evt.deviceCode === this.deviceCode) { return true; } else { return false; }
        })
      ) as Observable<SensorEvent>;
    }

    if (this.switchEvent) {
      this.switchEvent.subscribe((se: SensorEvent) => {
        //console.log('Event:' + this.assemblyCode + '#' + this.deviceCode + ':' + se.event);
        if (se.event === 'StateChanged' || se.event === 'CurrentState') {
          this.setSwithState(se.message);
          this.lastUpdate = new Date(se.timestamp);
          this.refreshLastUpdate();
        } else if (se.event === 'ManualOverride' || se.event === 'CurrentManualOverride') {
          this.setManualState(se.message);
          this.lastUpdate = new Date(se.timestamp);
          this.refreshLastUpdate();
        }
      });
    }
    // console.log('Log:' + this.assemblyCode + '#' + this.deviceCode + ' subscribed');
  }

  setSwithState(state: string) {
    this.switchState = state;
  }

  setManualState(state: string) {
    this.manualState = state;
  }

  queryState() {
    //console.log('queryState:');
    this.lastUpdate = null;
    this.lastUpdateDiff = "<<<";
    this.sensorService.publishCommand(this.assemblyCode, this.deviceCode, 'getStatus').subscribe((response) => {
      //console.log('publishCommand: getStatus, response:' + JSON.stringify(response));
    });
  }

  publishCommand(command: string) {
    this.lastUpdate = null;
    this.lastUpdateDiff = ">>>";
    this.sensorService.publishCommand(this.assemblyCode, this.deviceCode, command).subscribe((response) => {
      //console.log('publishCommand:' + command + ' response:' + JSON.stringify(response));
    });
  }

  ngOnInit() {

    // this.subscribe();
    // console.log('config:' + JSON.stringify(this.config));
    this.refreshLastUpdate();
  }


  public setData(data: any): void {
    this.data = data;
    if (data) {
      this.icon = data.icon || '';
      this.title = data.title;
      this.assemblyCode = data.assemblyCode;
      this.deviceCode = data.deviceCode;
      if (data['config']) {
        //console.log('assemblyCode:' + this.assemblyCode + ', deviceCode:' + this.deviceCode);
        this.config = { on: data.config['on'], auto: data.config['auto'] || {}, off: data.config['off'] || {} };
        //console.log('config:' + JSON.stringify(this.config));
      }
      this.subscribeOnEvents();
      this.queryState();
    }

  }

  private timeDiffText(date: Date): string {
    let ret: string;
    let newDate: Date = new Date();
    let diffMs = newDate.valueOf() - date.valueOf();

    //console.log("sinceLastUpdate:" + diffMs + " - " + newDate.valueOf() + " - " + date.valueOf() + " - " + date.toString());
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins == 0) {
      ret = "<1m";
    } else {
      ret = ((diffDays > 0) ? diffDays + "d " : "") + ((diffHrs > 0) ? diffHrs + "h " : "") + diffMins + "m";
    }
    return ret;
  }

  private refreshLastUpdate() {
    if (this.lastUpdate) {
      this.lastUpdateDiff = this.timeDiffText(this.lastUpdate);
    }
  }

}
