<mat-toolbar>
   <span>LOGIN</span>
</mat-toolbar>
<mat-card class="my-card">

   <mat-card-content>
      <form class="my-form" [formGroup]="loginForm" (ngSubmit)="login()">
         <mat-form-field class="full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" id="username" placeholder="Username" name="username">
         </mat-form-field>

         <mat-form-field class="full-width">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" formControlName="password" id="password" name="password" [type]="hidePassword ? 'password' : 'text'">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
               'visibility'}}</mat-icon>
         </mat-form-field>
      </form>
   </mat-card-content>
   <mat-card-actions>
      <button mat-raised-button (click)="login()" color="primary" [disabled]="loginForm.invalid">LOGIN</button>
   </mat-card-actions>

</mat-card>