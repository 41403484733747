<div class="grid-container">
  <!--
  <h1 class="mat-h1">Dashboard</h1>
  -->
  <mat-grid-list *ngIf="showDashboard" cols="6" rowHeight="120px">
    <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
      <!-- -->
      <app-sensor-metric-card [widget]="card"></app-sensor-metric-card>
      
    </mat-grid-tile>
  </mat-grid-list>
</div>