import { Component, OnInit, OnChanges, SimpleChanges, Inject, ChangeDetectionStrategy } from '@angular/core';
import { SensorService } from '../../sensor.service';
import { Observable, of, Subject, BehaviorSubject, interval } from 'rxjs';
import { SensorMetric } from '../../model/sensor-metric.model';
import { filter } from 'rxjs/operators';
import { formatDate, registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-temperature-widget',
  templateUrl: './temperature-widget.component.html',
  styleUrls: ['./temperature-widget.component.scss']
})
export class TemperatureWidgetComponent implements OnInit {


  currentTempMetric: string;
  tempMetric: Observable<SensorMetric>;
  assemblyCode: string;
  assemblyName: Subject<string> = new BehaviorSubject('Init...');
  deviceCode: string;
  tempUnit: string;
  currentHumidityMetric: string;
  humidityUnit: string;
  data: any;
  dataChange: Observable<any>;
  lastUpdate: Date;
  lastUpdateDiff: string = "...";

  secondsCounter = interval(1000 * 10);
  // Subscribe to begin publishing values

  subscription = this.secondsCounter.subscribe(n => {
    this.refreshLastUpdate();
  }  );

  public setData(data: any): void {
    this.data = data;
    if (data) {
      this.assemblyCode = data.assemblyCode;
      this.assemblyName.next(data.assemblyName || 'Unknown');
      this.deviceCode = data.deviceCode;
      this.loadLastMetrics();
      this.subscribeOnMetric(this.assemblyCode, this.deviceCode);
    }
  }


  constructor(private sensorService: SensorService, @Inject(LOCALE_ID) public locale: string) {
    this.tempUnit = '°C';
    this.humidityUnit = '%';
    this.dataChange = of(this.data);
  }

  ngOnInit() {
  }

  subscribeOnMetric(assemblyCode: string, deviceCode: string) {
    if (assemblyCode) {
      this.tempMetric = this.sensorService.metricStream.pipe(
        filter(evt => {
          if (evt.assemblyCode === assemblyCode
            && evt.deviceCode === deviceCode) {
            return true;
          } else { return false; }
        })
      ) as Observable<SensorMetric>;
    } else { this.assemblyName.next('Unknown'); }

    if (this.tempMetric) {
      this.tempMetric.subscribe((metric: SensorMetric) => {
        if (metric.metricCode === 'TEMPERATURE') {
          this.currentTempMetric = metric.metricValue.toFixed(0);
          this.lastUpdate = new Date(metric.timestamp);
        } else if (metric.metricCode === 'HUMIDITY') {
          this.currentHumidityMetric = metric.metricValue.toFixed(0);
          this.lastUpdate = new Date(metric.timestamp);
        }
        this.refreshLastUpdate();
        // this.lastUpdate = formatDate(metric.timestamp, 'yy.MM.dd HH:mm', this.locale);
        
      });
    } else { this.currentTempMetric = '-99'; }
  }

  private timeDiffText(date: Date): string {
    let ret: string;
    let newDate: Date = new Date();
    let diffMs = newDate.valueOf() - date.valueOf();

    // console.log("sinceLastUpdate:" + diffMs + " - " + newDate.valueOf() + " - " + date.valueOf() + " - " + date.toString());
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins == 0) {
      ret = "<1m";
    } else {
      ret = ((diffDays > 0) ? diffDays + "d " : "") + ((diffHrs > 0) ? diffHrs + "h " : "") + diffMins + "m";
    }
    return ret;
  }

  loadLastMetrics() {
    const dateFrom: Date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 5);
    this.sensorService.getLastMetrics(this.assemblyCode).subscribe((data) => {
      data.result
        .forEach((metric) => {
          if (metric.deviceCode === this.deviceCode) {
            if (metric.metricCode === 'TEMPERATURE') {
              this.currentTempMetric = this.currentTempMetric || metric.metricValue.toFixed(0);
              this.lastUpdate = new Date(metric.timestamp);
            } else if (metric.metricCode === 'HUMIDITY') {
              this.currentHumidityMetric = this.currentHumidityMetric || metric.metricValue.toFixed(0);
              this.lastUpdate = new Date(metric.timestamp);
            }
            this.refreshLastUpdate();
            //this.lastUpdate = this.lastUpdate || this.timeDiffText(new Date(metric.timestamp));
          }
        });
    });
  }

  private refreshLastUpdate() {
    if (this.lastUpdate) {
      this.lastUpdateDiff = this.timeDiffText(this.lastUpdate);
    }
  }
}


