import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './security/login/login.component';
import { UserListComponent } from './security/user-list/user-list.component';
import { SensorDashboardComponent } from './sensor/sensor-dashboard/sensor-dashboard.component';
import { UserEditComponent } from './security/user-edit/user-edit.component';
import { AuthGuard } from './security/auth.guard';
import { PasswordChangeComponent } from './security/password-change/password-change.component';
import { UserAddComponent } from './security/user-add/user-add.component';
import { SensorDashboardSettingsComponent } from './sensor/sensor-dashboard-settings/sensor-dashboard-settings.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'add-user', component: UserAddComponent, canActivate: [AuthGuard] },
  { path: 'edit-user', component: UserEditComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: PasswordChangeComponent, canActivate: [AuthGuard] },
  { path: 'userlist', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: SensorDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard-settings', component: SensorDashboardSettingsComponent, canActivate: [AuthGuard] },
  // { path: '', component: LoginComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

