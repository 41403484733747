import {
  Component, OnInit, ComponentRef, Input, ViewChild, ViewContainerRef,
  ComponentFactoryResolver, AfterViewInit
} from '@angular/core';
import { SensorWidget } from '../model/sensor-widget.model';
import { SensorDashboardService } from '../sensor-dashboard.service';


@Component({
  selector: 'app-sensor-metric-card',
  templateUrl: './sensor-metric-card.component.html',
  styleUrls: ['./sensor-metric-card.component.scss']
})
export class SensorMetricCardComponent implements OnInit, AfterViewInit {
  componentRef: ComponentRef<any>;

  @Input() widget: SensorWidget;
  @Input() isUnderMaintenance: Boolean;

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private sensorDashboardService: SensorDashboardService) {}

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.sensorDashboardService.ALL_COMPONENT_CLASSES[this.widget.component]);
    this.dynamicComponentContainer.clear();

    this.componentRef = this.dynamicComponentContainer.createComponent(componentFactory);
    this.componentRef.instance.setData(this.widget.data);

  }

  ngAfterViewInit(): void {
    // this.componentRef.instance.setData(this.widget.data);
  }


}
