import { Component } from '@angular/core';
import { SecurityService } from './security/security.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SenseStatUi';

  messageCount: Number;
  showMessageCounter: Boolean = true;

  constructor(private securityService: SecurityService, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('metric_temperature', domSanitizer.bypassSecurityTrustResourceUrl('assets/thermometer.svg'));
    matIconRegistry.addSvgIcon('metric_humidity', domSanitizer.bypassSecurityTrustResourceUrl('assets/humidity.svg'));
    matIconRegistry.addSvgIcon('metric_soil_temperature', domSanitizer.bypassSecurityTrustResourceUrl('assets/soil_temp.svg'));
    matIconRegistry.addSvgIcon('metric_soil_moisture', domSanitizer.bypassSecurityTrustResourceUrl('assets/soil_moisture.svg'));
    matIconRegistry.addSvgIcon('metric_soil_heater', domSanitizer.bypassSecurityTrustResourceUrl('assets/soil_heater.svg'));
    matIconRegistry.addSvgIcon('metric_greenhouse', domSanitizer.bypassSecurityTrustResourceUrl('assets/greenhouse.svg'));
    matIconRegistry.addSvgIcon('metric_irrigation', domSanitizer.bypassSecurityTrustResourceUrl('assets/irrigation.svg'));
    matIconRegistry.addSvgIcon('metric_watering', domSanitizer.bypassSecurityTrustResourceUrl('assets/farming-and-gardening.svg'));
  }

  logout(): void {
    this.securityService.logout();
  }

  isAuthenticated() {
    return this.securityService.isLoggedIn();
  }

}
