<mat-toolbar>
    <span>CHANGE THE PASSWORD</span>
 </mat-toolbar>
 <mat-card class="my-card">
 
    <mat-card-content>
       <form class="my-form" [formGroup]="passwordForm" (ngSubmit)="changePassword()">

          <mat-form-field class="full-width">
              <mat-label>Username</mat-label>
              <input matInput formControlName="username" id="username" name="username" [type]="'text'">
           </mat-form-field>

          <mat-form-field class="full-width">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Type a password" formControlName="password" id="password" name="password" [type]="hidePassword ? 'password' : 'text'">
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
                 'visibility'}}</mat-icon>
           </mat-form-field>
 
          <mat-form-field class="full-width">
             <mat-label>Re-type the password</mat-label>
             <input matInput placeholder="Type the same password again" formControlName="password2" id="password2" name="password2" [type]="hidePassword2 ? 'password' : 'text'">
             <mat-icon matSuffix (click)="hidePassword2 = !hidePassword2">{{hidePassword2 ? 'visibility_off' :
                'visibility'}}</mat-icon>
          </mat-form-field>
       </form>
    </mat-card-content>
    <mat-card-actions>
       <button mat-raised-button (click)="changePassword()" color="primary" [disabled]="passwordForm.invalid">SAVE</button>
       <button mat-raised-button (click)="cancel()" color="accent">CANCEL</button>
    </mat-card-actions>
 
 </mat-card>