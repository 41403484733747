import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SensorDashboardService } from '../sensor-dashboard.service';
import { SensorDashboard } from '../model/sensor-dashboard.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sensor-dashboard-settings',
  templateUrl: './sensor-dashboard-settings.component.html',
  styleUrls: ['./sensor-dashboard-settings.component.scss']
})
export class SensorDashboardSettingsComponent implements OnInit {

  dashboardForm: FormGroup;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: SensorDashboardService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.dashboardForm = this.formBuilder.group({
      settings: ['']
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get('returnUrl');
      this.dashboardService.getDashboardList().subscribe((response) => {
        this.dashboardForm.patchValue({ settings: response.result });
      });
    });
  }

  cancel() {
    if (this.returnUrl === null || this.returnUrl === undefined) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  getDefault() {
    this.dashboardForm.patchValue({ settings: this.dashboardService.defaultDashboard });
  }

  saveOne(dashboard: SensorDashboard) {
    if (dashboard.id === null || dashboard.id === undefined) {
      this.dashboardService.saveDashboard(dashboard).subscribe((response) => {
        this.snackAlert('Dashboard ' + dashboard.media + ' saved!', 'OK');
      });
    } else {
      this.dashboardService.updateDashboard(dashboard).subscribe((response) => {
        this.snackAlert('Dashboard ' + dashboard.media + ' updated!', 'OK');
      });
    }
  }

  save() {
    const dashboards: SensorDashboard[] = this.dashboardForm.value['settings'];
    dashboards.forEach(dashboard => this.saveOne(dashboard));
  }

  snackAlert(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
