import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SensorMetric } from '../model/sensor-metric.model';
import { SensorService } from '../sensor.service';

@Component({
  selector: 'app-sensor-metric-display',
  templateUrl: './sensor-metric-display.component.html',
  styleUrls: ['./sensor-metric-display.component.scss']
})
export class SensorMetricDisplayComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;
  @Input() metricCode: string;
  @Input() metricUnit: string;


  assemblyCode: string;
  assemblyName: Subject<string> = new BehaviorSubject('Init...');
  deviceCode: string;
  metricValue: string;
  lastUpdate: Date;
  lastUpdateDiff: string = "...";

  tempMetric: Observable<SensorMetric>;

  data: any;

  secondsCounter = interval(1000 * 10);
  // Subscribe to begin publishing values

  subscription = this.secondsCounter.subscribe(n => {
    this.refreshLastUpdate();
  });

  public setData(data: any): void {
    this.data = data;
    if (data) {
      this.assemblyCode = data.assemblyCode;
      this.deviceCode = data.deviceCode;
      this.icon = data.icon || this.icon;
      this.metricCode = data.metricCode || this.metricCode;
      this.metricUnit = data.metricUnit || this.metricUnit;
      this.title = data.title;
      this.loadLastMetrics();
      this.subscribeOnMetric(this.assemblyCode, this.deviceCode, this.metricCode);
      this.publishCommand('getmetric', this.metricCode);
    }
  }

  constructor(private sensorService: SensorService,
    @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
  }

  subscribeOnMetric(assemblyCode: string, deviceCode: string, metricCode: String) {
    if (assemblyCode) {
      this.tempMetric = this.sensorService.metricStream.pipe(
        filter(evt => {
          if (evt.assemblyCode === assemblyCode
            && evt.deviceCode === deviceCode) {
            return true;
          } else { return false; }
        })
      ) as Observable<SensorMetric>;
    } else { this.assemblyName.next('Unknown'); }

    if (this.tempMetric) {
      this.tempMetric.subscribe((metric: SensorMetric) => {
        if (metric.metricCode === metricCode) {
          this.metricValue = metric.metricValue.toFixed(0);
          this.lastUpdate = new Date(metric.timestamp);
          this.refreshLastUpdate();
        }
      });
    } else { this.metricValue = 'N/A'; }
  }

  loadLastMetrics() {
    const dateFrom: Date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 5);
    this.sensorService.getLastMetrics(this.assemblyCode).subscribe((data) => {
      data.result
        .forEach((metric) => {
          if (metric.metricCode === this.metricCode && metric.deviceCode === this.deviceCode) {
            this.metricValue = this.metricValue || metric.metricValue.toFixed(0);
            this.lastUpdate = new Date(metric.timestamp);
            this.refreshLastUpdate();
          }

        });
    });
  }

  publishCommand(command: string, params: string) {
    this.sensorService.publishCommand(this.assemblyCode, this.deviceCode, command, params).subscribe((response) => {
      console.log('publishCommand:' + command + ' response:' + JSON.stringify(response));
    });
  }

  private timeDiffText(date: Date): string {
    let ret: string;
    let newDate: Date = new Date();
    let diffMs = newDate.valueOf() - date.valueOf();

    //console.log("sinceLastUpdate:" + diffMs + " - " + newDate.valueOf() + " - " + date.valueOf() + " - " + date.toString());
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins == 0) {
      ret = "<1m";
    } else {
      ret = ((diffDays > 0) ? diffDays + "d " : "") + ((diffHrs > 0) ? diffHrs + "h " : "") + diffMins + "m";
    }
    return ret;
  }

  private refreshLastUpdate() {
    if (this.lastUpdate) {
      this.lastUpdateDiff = this.timeDiffText(this.lastUpdate);
    }
  }

}
