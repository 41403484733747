import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SecurityService } from '../security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../model/user.model';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {
  userForm: FormGroup;
  returnUrl: string;
  userId: string;
  user: User = new User();

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute,
    private securityService: SecurityService) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      firstName: [''],
      lastName: [''],
      password: [''],
      emailAddress: ['']
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get('returnUrl');
    });
  }

  cancel() {
    if (this.returnUrl === null || this.returnUrl === undefined || this.returnUrl === '') {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  save() {
    this.securityService.saveUser(this.userForm.value).subscribe((response) => {
      alert('User saved!');
    });
    this.cancel();
  }

}
