import { Component, OnInit } from '@angular/core';
import { User } from '../model/user.model';
import { SecurityService } from '../security.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  displayedColumns: string[] = ['id', 'firstname', 'lastname', 'username', 'email', 'operation'];
  userList: Array<User>;
  dataSource: Array<User>; // = [{ id: 1, firstname: 'Mark', lastname: 'Kolovics', username: 'mark' }];
  dataLoaded = false;

  constructor(private securityService: SecurityService, private router: Router) { }

  ngOnInit() {
    this.updateUserList();
  }

  updateUserList(): void {
    this.dataLoaded = false;
    this.securityService.getUserList().subscribe(
      data => {
        this.userList = data.result;
        this.dataLoaded = true;
      }
    );
  }

  backToDashboard(): void {
    this.router.navigate(['dashboard']);
  }


  editUser(user: User): void {
    const userId = user ? user.id : null;
    this.router.navigate(['edit-user'], { queryParams: { returnUrl: this.router.url, userId: userId } });
  }

  addUser(): void {
    this.router.navigate(['add-user'], { queryParams: { returnUrl: this.router.url } });
  }

  deleteUser(user: User): void {
    this.securityService.deleteUser(user.id).subscribe((response) => {
      alert('User deleted!(' + user.id + ')');
      this.updateUserList();
    });
  }

  changePassword(user: User): void {
    if (!(user === null || user === undefined)) {
      this.router.navigate(['change-password'], { queryParams: { returnUrl: this.router.url, userId: user.id } });
    }
  }
}
