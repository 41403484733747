<mat-toolbar>
  <span>ADD USER</span>
</mat-toolbar>
<mat-card class="my-card">

  <mat-card-content>
    <form class="my-form" [formGroup]="userForm">

      <mat-form-field class="full-width">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" id="username" placeholder="Username" name="username">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Firstname</mat-label>
        <input matInput formControlName="firstName" id="firstName" placeholder="Firstname" name="firstName">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Lastname</mat-label>
        <input matInput formControlName="lastName" id="lastName" placeholder="Lastname" name="lastName">
      </mat-form-field>


      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="Password" formControlName="password" id="password" name="password">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="emailAddress" id="emailAddress" placeholder="emailAddress" name="emailAddress">
      </mat-form-field>
      

    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="save()" color="primary" [disabled]="userForm.invalid">SAVE</button>
    <button mat-raised-button (click)="cancel()" color="accent">CANCEL</button>
  </mat-card-actions>

</mat-card>