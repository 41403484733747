import { Component, OnInit, forwardRef, Input, OnDestroy, ElementRef, Optional, Self } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormControl, NgControl, FormBuilder } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-mat-json-editor',
  templateUrl: './mat-json-editor.component.html',
  styleUrls: ['./mat-json-editor.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MatJsonEditorComponent
    }]
})
export class MatJsonEditorComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {

  id: string;
  errorState = false;
  controlType = 'app-mat-json-editor';

  stateChanges = new Subject<void>();
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _value: any;

  focused = false;
  describedBy = '';
  jsonString: string;
  private parseError: boolean;

  get empty() {
    return !this._value;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): any | null {
    return this._value;
  }
  set value(value: any | null) {
    this._value = value;
    this.stateChanges.next();
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {


    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  // this is the initial value set to the component
  public writeValue(obj: any) {
    this._value = obj || '{}';
    this.jsonString = JSON.stringify(this._value, undefined, 3);
  }

  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTouched = () => { };

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return (!this.parseError) ? null : {
      jsonParseError: {
        valid: false,
      },
    };
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    /*if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input')!.focus();
    }*/
  }


  // change events from the textarea
  onChange(event) {

    // get value from text area
    const newValue = event.target.value;

    try {
      // parse it to json
      this._value = JSON.parse(newValue || '{}');
      this.errorState = false;
    } catch (ex) {
      // set parse error if it fails
      this.errorState = true;
    }

    // update the form
    this.propagateChange(this._value);
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any) => { };

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

}
