<div *ngIf="!this.dataLoaded">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="this.dataLoaded">
  <mat-toolbar>
    <mat-toolbar-row>
      <button mat-icon-button (click)="backToDashboard()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>USERLIST</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-card class="my-card">
    <mat-card-content>
      <mat-table [dataSource]="userList" class="mat-elevation-z8">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Unique ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef> UserName </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
        </ng-container>

        <!-- Firstname Column -->
        <ng-container matColumnDef="firstname">
          <mat-header-cell *matHeaderCellDef> Firstname </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
        </ng-container>

        <!-- Lastname Column -->
        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef> Lastname </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.emailAddress}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button class="action-button" mat-raised-button (click)="changePassword(element)" color="primary">
              <mat-icon>lock</mat-icon>
            </button>
            <button class="action-button" mat-raised-button (click)="editUser(element)" color="primary">
              <mat-icon>edit</mat-icon>
            </button>
            <button class="action-button" mat-raised-button (click)="deleteUser(element)" color="primary">
              <mat-icon>delete</mat-icon>
            </button> </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="addUser()" color="primary">ADD USER</button>
      <button mat-raised-button (click)="backToDashboard()" color="accent">BACK</button>
    </mat-card-actions>
  </mat-card>
</div>