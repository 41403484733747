import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../security.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  invalidLogin = false;
  hidePassword = true;

  constructor(private formBuilder: FormBuilder, private router: Router, private securityService: SecurityService) { }

  ngOnInit() {
    window.localStorage.removeItem('token');
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required]
    });
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    };

    this.securityService.login(loginPayload).subscribe(data => {
    }, error => {
      this.invalidLogin = true;
      alert(error.message);
    });
  }
}
