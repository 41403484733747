// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // These URLs are extracted from environment variables for Docker based flexible deployment!
  backend_url: window["env"]["backend_url"], // || 'http://localhost:8888',
  sensor_websocket_url: window["env"]["sensor_websocket_url"],// || 'ws://localhost:8888/wschannel',

  // TODO: Remove the following configurations & use put & delete operations!

  user_list_url: '/users/',
  user_get_url: '/users/',
  user_post_url: '/users/',
  user_put_url: '/users/update/',
  user_delete_url: '/users/delete/',
  token_url: '/auth/generate-token',
  change_password_url: '/auth/change-password/',

  sensor_data_topic: '/topic/datamessages',
  sensor_event_topic: '/topic/eventmessages',
  sensor_query_url: '/sensorquery/',
  sensor_last_metric: '/sensorlastmetrics/',
  sensor_publish_command_url: '/sensorcommand/publish',

  // Sensor dashboard
  sensor_dashboard_list_url: '/sensordashboard/',
  sensor_dashboard_get_url: '/sensordashboard/',
  sensor_dashboard_post_url: '/sensordashboard/',
  sensor_dashboard_put_url: '/sensordashboard/update/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
