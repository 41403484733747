import { Component, OnInit } from '@angular/core';
import { SensorWidget } from '../../model/sensor-widget.model';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-incubator-widget',
  templateUrl: './incubator-widget.component.html',
  styleUrls: ['./incubator-widget.component.scss']
})
export class IncubatorWidgetComponent implements OnInit {

  incubatorName: string;
  // lastUpdate: string;
  // tempUnit: string;
  // currentHumidityMetric: string;
  // humidityUnit: string;
  slots: Observable<SensorWidget[]>;

  data: any;

  constructor() {
    // this.tempUnit = '°C';
    // this.humidityUnit = '%';
  }

  ngOnInit() {
  }

  public setData(data: any): void {
    this.data = data;
    if (data) {
      this.incubatorName = data.incubatorName || 'Unknown';
      this.slots = of(data.slots);
    }

  }
}
