import { Type } from '@angular/core';


export class SensorWidget {
    constructor(
        public title: string,
        public cols: number,
        public rows: number,
        public component: string,
        public data: any
    ) { }

    // openSettings() { }
}
