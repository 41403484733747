import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../security.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  userForm: FormGroup;
  returnUrl: string;
  userId: string;
  // user: User = new User();

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute,
    private securityService: SecurityService) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: [''],
      username: ['', Validators.compose([Validators.required])],
      firstName: [''],
      lastName: [''],
      emailAddress: ['']
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get('returnUrl');
      this.userId = params.get('userId');
      if (this.userId === null || this.userId === undefined) {
        alert('User ID is empty!');
        this.cancel();
      } else {
        this.securityService.getUser(this.userId).subscribe((response) => {
          this.userForm.patchValue(response.result);
          this.userForm.controls.id.disable();
          this.userForm.controls.username.disable();
        });
      }
    });
  }

  cancel() {
    if (this.returnUrl  === null || this.returnUrl === undefined || this.returnUrl === '') {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  save() {
    this.userForm.controls.id.enable();
    this.userForm.controls.username.enable();
    this.securityService.updateUser(this.userForm.value).subscribe((response) => {
      alert('User updated!');
      this.cancel();
    });
   }
}
