import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SensorWidget } from '../model/sensor-widget.model';
import { Observable } from 'rxjs';
import { SensorDashboardService } from '../sensor-dashboard.service';

@Component({
  selector: 'app-sensor-dashboard',
  templateUrl: './sensor-dashboard.component.html',
  styleUrls: ['./sensor-dashboard.component.scss']
})
export class SensorDashboardComponent implements OnInit {

  response: SensorWidget[];

  public JSON: JSON;

  public dashboardColNums = 6;
  public dashboardRowHeight = '120px';
  showDashboard = false;

  cards: Observable<SensorWidget[]> =
    this.breakpointObserver.observe([
      this.sensorDashboardService.iPhoneMaxWidthPortrait,
      this.sensorDashboardService.iPhoneMaxWidthLandscape,
      Breakpoints.Handset])
      .pipe(
        map(({ breakpoints }) => {
          if (breakpoints[this.sensorDashboardService.iPhoneMaxWidthPortrait]) {
            this.response = this.sensorDashboardService.getDashboardByMedia(this.sensorDashboardService.iPhoneMaxWidthPortrait);
          } else if (breakpoints[this.sensorDashboardService.iPhoneMaxWidthLandscape]) {
            this.response = this.sensorDashboardService.getDashboardByMedia(this.sensorDashboardService.iPhoneMaxWidthLandscape);
          } else if (breakpoints['Handset']) {
            this.response = this.sensorDashboardService.getDashboardByMedia('Handset');
          } else {
            this.response = this.sensorDashboardService.getDashboardByMedia('other');
          }
          return this.response;
        })
      );

  // Link: https://github.com/angular/components/blob/master/src/cdk/layout/breakpoints.ts
  /*
  breakP = [Breakpoints.XSmall,
  Breakpoints.Small,
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge,
  Breakpoints.Handset,
  Breakpoints.Tablet,
  Breakpoints.Web,
  Breakpoints.HandsetPortrait,
  Breakpoints.TabletPortrait,
  Breakpoints.WebPortrait,
  Breakpoints.HandsetLandscape,
  Breakpoints.TabletLandscape,
  Breakpoints.WebLandscape]
  */

  constructor(private breakpointObserver: BreakpointObserver, private sensorDashboardService: SensorDashboardService) { }

  ngOnInit(): void {
    this.sensorDashboardService.initDashboard().subscribe(() => this.showDashboard = true);
  }
}
