<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>
      {{incubatorName}}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="dashboard-card-content">
    <!--
    <div class="dual-metric-container">
      <app-sensor-metric-display class="metric-horizontal-container" metricIcon="metric_temperature" metricValue="11"
        metricUnit="{{tempUnit}}">
      </app-sensor-metric-display>
      <app-sensor-metric-display class="metric-horizontal-container" metricIcon="metric_humidity" metricValue="44"
        metricUnit="{{humidityUnit}}">
      </app-sensor-metric-display>
    </div>
     
    <div class="dual-metric-container">
    <app-sensor-metric-display class="metric-horizontal-container" metricIcon="metric_soil_temperature" metricValue="16"
      metricUnit="{{tempUnit}}">
    </app-sensor-metric-display>
    <app-sensor-metric-display class="metric-horizontal-container" metricIcon="metric_soil_moisture" metricValue="35"
      metricUnit="{{humidityUnit}}">
    </app-sensor-metric-display>
    </div>
  -->
    <mat-grid-list cols="1" rowHeight="90px">
      <mat-grid-tile *ngFor="let slot of slots | async">
        <!-- -->
        <app-sensor-metric-card class="metric-card" [widget]="slot"></app-sensor-metric-card>

      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>