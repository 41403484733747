import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecurityModule } from './security/security.module';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SensorModule } from './sensor/sensor.module';
import { TokenInterceptor } from './security/token.interceptor';
import { SensorService } from './sensor/sensor.service';
import { CommonModule } from '@angular/common';
import { RxStompService } from '@stomp/ng2-stompjs';
import { WebsocketService } from './websocket/websocket.service';
import { WebsocketModule } from './websocket/websocket.module';
import { SecurityService } from './security/security.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CustomMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SecurityModule,
    ReactiveFormsModule,
    HttpClientModule,
    SensorModule,
    WebsocketModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
    SensorService,
    RxStompService,
    SecurityService,
    WebsocketService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
