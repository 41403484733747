import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { User } from './model/user.model';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from './model/api-response.model';
import { Router } from '@angular/router';
import { Token } from './model/token.model';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {


  _isLoggedIn = false;
  _loggedInUserName = '';

  redirectUrl: string;

  private _userEvent: Subject<string> = new Subject();

  public userEvent(): Observable<string> { return this._userEvent; }

  isLoggedIn(): any {
    return this._isLoggedIn;
  }

  getLoggedInUserName(): string {
    return this._loggedInUserName;
  }



  constructor(private http: HttpClient, private router: Router) { }

  getUserList(): Observable<ApiResponse<Array<User>>> {
    return this.http.get<ApiResponse<Array<User>>>(environment.backend_url + environment.user_list_url);
  }

  getUser(userId: string): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(environment.backend_url + environment.user_list_url + userId);
  }

  saveUser(user: User): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(environment.backend_url + environment.user_post_url, user);
  }

  updateUser(user: User): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(environment.backend_url + environment.user_put_url, user);
  }

  deleteUser(userId: string): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(environment.backend_url + environment.user_delete_url, { id: userId });
  }

  /*
  login(loginPayload): Observable<ApiResponse<Token>> {
    return this.http.post<ApiResponse<Token>>(environment.backend_url + environment.token_url, loginPayload);
  }
  */

  login(loginPayload): Observable<ApiResponse<Token>> {
    const result: Observable<ApiResponse<Token>> = new Observable(
      observer => {
        this.http.post<ApiResponse<Token>>(environment.backend_url + environment.token_url, loginPayload)
          .subscribe(
            data => {
              window.localStorage.setItem('token', data.result.token);
              this._isLoggedIn = true;
              this._loggedInUserName = loginPayload.username;
              this._userEvent.next('login');
              observer.next(data);
              observer.complete();

              // TODO: Is it the best place of it?
              if (this.redirectUrl === null || this.redirectUrl === undefined) {
                this.router.navigate(['']);
              } else {
                this.router.navigate([this.redirectUrl]);
              }
            },
            error => {
              window.localStorage.removeItem('token');
              this._isLoggedIn = false;
              this._loggedInUserName = '';
              this._userEvent.next('login-error');
              observer.error(error);
            });
      }
    );
    return result;
  }

  logout(): void {
    this._isLoggedIn = false;
    this._loggedInUserName = '';
    this.redirectUrl = '';
    window.localStorage.removeItem('token');
    this.router.navigate(['login']);
    this._userEvent.next('logout');
  }

  changePassword(passwordPayload): Observable<string> {
    return this.http.post<string>(environment.backend_url + environment.change_password_url, passwordPayload);
  }

  getToken(): string {
    return window.localStorage.getItem('token');
  }
}
