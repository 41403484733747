<div class="switch-container">
  <div class="switch-title">{{title}}</div>
  <!--
  <mat-icon svgIcon="{{icon}}" class="switch-icon"></mat-icon>
-->
  <mat-button-toggle-group name="switch" aria-label="Switch">
    <mat-button-toggle class="mat-small" [disabled]= "config.on.disabled" [ngClass] = "config.on.class" value="on" (click)="publishCommand('manual on')">ON</mat-button-toggle>
    <mat-button-toggle class="mat-small" [disabled]= "config.auto.disabled" [ngClass] = "config.auto.class" value="auto" (click)="publishCommand('auto')">AUTO</mat-button-toggle>
    <mat-button-toggle class="mat-small" [disabled]= "config.off.disabled" [ngClass] = "config.off.class" value="off" (click)="publishCommand('manual off')">OFF</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-icon class="mat-icon-large" [ngClass]="(switchState=='1')?'switch-on':'switch-off'">lens</mat-icon>
  <mat-icon class="mat-icon-large" [ngClass]="(manualState=='1')?'manual-on':'manual-off'">pan_tool</mat-icon>
  <div class="switch-lastupdate">{{lastUpdateDiff}}</div> 
</div>