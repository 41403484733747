<div class="metric-container">
  <div>
    <div class="metric-title">{{title}}</div>
  </div>
  <div class="metric-display">
    <mat-icon svgIcon="{{icon}}" class="metric-icon"></mat-icon>
    <span class="metric-value">{{metricValue}}</span>
    <span class="metric-unit">{{metricUnit}}</span>
    <div class="metric-lastupdate">{{lastUpdateDiff}}</div> 
  </div>
</div>