import { Injectable } from '@angular/core';
// import * as Rx from 'rxjs';
import { environment } from '../../environments/environment';
import { SensorMetric } from './model/sensor-metric.model';
import { Observable, Subject, of, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../security/model/api-response.model';
import { Message } from '@stomp/stompjs';
import { SecurityService } from '../security/security.service';
import { WebsocketService } from '../websocket/websocket.service';
import { SensorEvent } from './model/sensor-event.model';


@Injectable({
  providedIn: 'root'
})
export class SensorService {

  public sensorMetrics: string[] = ['temp', 'pressure', 'humidity', 'moisture', 'switch_state'];

  public getSensorMetrics: Observable<string[]> = of(this.sensorMetrics);

  private metricSubscription: Subscription;
  metricStream: Subject<SensorMetric>;

  private eventSubscription: Subscription;
  eventStream: Subject<SensorEvent>;

  constructor(private http: HttpClient, private securityService: SecurityService, private websocketService: WebsocketService) {
    this.metricStream = new Subject();
    this.eventStream = new Subject();

    if (this.securityService._isLoggedIn) {
      // Already logged in, we just subscribe
      this.subscribeStomp();
    }

    this.securityService.userEvent().subscribe(event => {
      if (event === 'login') {
        this.subscribeStomp();
      } else if (event === 'logout') {
        this.unsubscribeStomp();
      }
    });

  }

  private subscribeStomp(): void {
    this.metricSubscription = this.websocketService.subscribeTopic(environment.sensor_data_topic).subscribe((message: Message) => {
      if (message.body) {
        const metric: SensorMetric = JSON.parse(message.body);
        this.broadcastMetric(metric);
      }
    });

    this.eventSubscription = this.websocketService.subscribeTopic(environment.sensor_event_topic).subscribe((message: Message) => {
      if (message.body) {
        const event: SensorEvent = JSON.parse(message.body);
        console.log('Event:' + JSON.stringify(event));
        this.broadcastEvent(event);
      }
    });

  }

  unsubscribeStomp() {
    this.metricSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  public broadcastMetric(metric: SensorMetric): void { this.metricStream.next(metric); }

  public broadcastEvent(event: SensorEvent): void { this.eventStream.next(event); }

  public getLastMetrics(assemblyCode: string): Observable<ApiResponse<SensorMetric[]>> {
    return this.http.get<ApiResponse<SensorMetric[]>>(environment.backend_url + environment.sensor_last_metric,
      { params: { assemblyCode: assemblyCode } });
  }

  public publishCommand(assemblyCode: string, deviceCode: string, command: string, params: string = "") {
    return this.http.post<ApiResponse<string>>(environment.backend_url + environment.sensor_publish_command_url,
      { assemblyCode: assemblyCode, deviceCode: deviceCode, command: command, params:params });
  }
}
