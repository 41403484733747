import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../security.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  passwordForm: FormGroup;
  returnUrl: string;
  hidePassword = true;
  hidePassword2 = true;

  userId: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute,
    private securityService: SecurityService) { }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      username: [''],
      password: ['', Validators.compose([Validators.required])],
      password2: ['', Validators.required]
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get('returnUrl');
      this.userId = params.get('userId');
      if (this.userId === null || this.userId === undefined) {
      } else {
        this.securityService.getUser(this.userId).subscribe((response) => {
          this.passwordForm.patchValue({ username: response.result.username });
          this.passwordForm.get('username').disable();
        });
      }
    });
  }

  changePassword() {
    if (this.passwordForm.invalid) {
      return;
    }
    const passwordPayload = {
      username: this.passwordForm.controls.username.value,
      password: this.passwordForm.controls.password.value
    };

    this.securityService.changePassword(passwordPayload).subscribe(data => {
      alert('Password changed!');
      if (this.securityService.redirectUrl === null || this.securityService.redirectUrl === undefined) {
        this.router.navigate(['']);
      } else {
        this.router.navigate([this.securityService.redirectUrl]);
      }
    }, error => {
      alert(error.message);
    });
  }

  cancel() {
    if ( this.returnUrl === null || this.returnUrl === undefined || this.returnUrl === '') {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }
}
