import { Injectable } from '@angular/core';
import { TemperatureWidgetComponent } from './sensor-widgets/temperature-widget/temperature-widget.component';
import { IncubatorWidgetComponent } from './sensor-widgets/incubator-widget/incubator-widget.component';
import { SensorWidget } from './model/sensor-widget.model';
import { Observable } from 'rxjs';
import { ApiResponse } from '../security/model/api-response.model';
import { SensorDashboard } from './model/sensor-dashboard.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IrrigationWidgetComponent } from './sensor-widgets/irrigation-widget/irrigation-widget.component';
import { SensorSwitchComponent } from './sensor-switch/sensor-switch.component';
import { SensorMetricDisplayComponent } from './sensor-metric-display/sensor-metric-display.component';

@Injectable({
  providedIn: 'root'
})
export class SensorDashboardService {

  public ALL_COMPONENT_CLASSES = {
    TemperatureWidget: TemperatureWidgetComponent,
    IncubatorWidget: IncubatorWidgetComponent,
    IrrigationWidget: IrrigationWidgetComponent,
    SensorSwitchWidget: SensorSwitchComponent,
    SensorMetricDisplay: SensorMetricDisplayComponent
  };

  public iPhoneMaxWidthPortrait = '(max-width: 414px) and (orientation: portrait)';
  public iPhoneMaxWidthLandscape = '(max-width: 736px) and (orientation: landscape)';

  private _dashboard: SensorDashboard[];

  public supportedMedia = [
    { name: 'Iphone 6/7/8, Iphone 6/7/8 plus', query: this.iPhoneMaxWidthPortrait },
    { name: 'Handset', query: 'Handset' },
    { name: 'Other', query: 'other' }
  ];

  public defaultDashboard: SensorDashboard[] = [
    {
      id: null, cols: 6, rowHeight: '120px',
      media: this.iPhoneMaxWidthPortrait, widgets: [
        {
          component: 'TemperatureWidget', title: 'Lakás', cols: 3, rows: 2,
          data: { sensorName: 'Lakás', sensorCode: 'metric2' }
        },
        {
          component: 'TemperatureWidget', title: 'Üvegház', cols: 3, rows: 2,
          data: { sensorName: 'Üvegház', sensorCode: 'metric1' }
        },
        {
          component: 'TemperatureWidget', title: 'Fóliasátor', cols: 3, rows: 2,
          data: { sensorName: 'Fóliasátor', sensorCode: 'metric2' }
        }
      ]
    },
    {
      id: null, cols: 6, rowHeight: '120px',
      media: this.iPhoneMaxWidthLandscape, widgets: [
        {
          component: 'TemperatureWidget', title: 'Lakás', cols: 2, rows: 2,
          data: { sensorName: 'Lakás', sensorCode: 'metric2' }
        },
        {
          component: 'TemperatureWidget', title: 'Üvegház', cols: 2, rows: 2,
          data: { sensorName: 'Üvegház', sensorCode: 'metric1' }
        },
        {
          component: 'TemperatureWidget', title: 'Fóliasátor', cols: 2, rows: 2,
          data: { sensorName: 'Fóliasátor', sensorCode: 'metric2' }
        }
      ]
    },
    {
      id: null, cols: 6, rowHeight: '120px',
      media: 'Handset', widgets: [
        {
          component: 'TemperatureWidget', title: 'Lakás', cols: 2, rows: 2,
          data: { sensorName: 'Lakás', sensorCode: 'metric2' }
        },
        {
          component: 'TemperatureWidget', title: 'Üvegház', cols: 2, rows: 2,
          data: { sensorName: 'Üvegház', sensorCode: 'metric1' }
        },
        {
          component: 'TemperatureWidget', title: 'Fóliasátor', cols: 2, rows: 2,
          data: { sensorName: 'Fóliasátor', sensorCode: 'metric2' }
        }
      ]
    },
    {
      id: null, cols: 6, rowHeight: '120px',
      media: 'other', widgets: [
        {
          component: 'TemperatureWidget', title: 'Lakás', cols: 1, rows: 2,
          data: { sensorName: 'Lakás', sensorCode: 'metric2' }
        },
        {
          component: 'TemperatureWidget', title: 'Üvegház', cols: 1, rows: 2,
          data: { sensorName: 'Üvegház', sensorCode: 'metric1' }
        },
        {
          component: 'TemperatureWidget', title: 'Fóliasátor', cols: 1, rows: 2,
          data: { sensorName: 'Fóliasátor', sensorCode: 'metric3' }
        }
      ]
    }];

  public getDashboardByMedia(media: String): SensorWidget[] {
    let dashboardParam = this._dashboard.find((element) => element.media === media);
    if (!dashboardParam) {
      dashboardParam = this._dashboard.find((element) => element.media === 'other');
    }

    let dashboard: SensorWidget[];
    if (dashboardParam) {
      dashboard = dashboardParam.widgets.map((component) => new SensorWidget(component.title,
        component.cols,
        component.rows,
        component.component, JSON.parse(component.data)));
    }
    return dashboard;
  }

  public initDashboard(): Observable<void> {
    const _observable: Observable<void> = new Observable((observer) => {
      this.getDashboardList().subscribe(
        response => {
          if (response.result === null || response.result === undefined) {
            this._dashboard = this.defaultDashboard;
          } else {
            this._dashboard = response.result;
          }
          observer.next();
          observer.complete();
        },
        error => {
          this._dashboard = this.defaultDashboard;
          observer.next();
          observer.complete();
        });
    });

    return _observable;
  }

  public getDashboardList(): Observable<ApiResponse<SensorDashboard[]>> {
    return this.http.get<ApiResponse<SensorDashboard[]>>(environment.backend_url + environment.sensor_dashboard_list_url,
      { params: {} });
  }

  public getDashboard(sensorId: string): Observable<ApiResponse<SensorDashboard>> {
    return this.http.get<ApiResponse<SensorDashboard>>(environment.backend_url + environment.sensor_dashboard_get_url + sensorId);
  }

  public saveDashboard(dashboard: SensorDashboard): Observable<ApiResponse<SensorDashboard>> {
    return this.http.post<ApiResponse<SensorDashboard>>(environment.backend_url + environment.sensor_dashboard_post_url, dashboard);
  }

  public updateDashboard(dashboard: SensorDashboard): Observable<ApiResponse<SensorDashboard>> {
    return this.http.post<ApiResponse<SensorDashboard>>(environment.backend_url + environment.sensor_dashboard_put_url, dashboard);
  }

  constructor(private http: HttpClient) { }
}
