import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { UserListComponent } from './user-list/user-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserEditComponent } from './user-edit/user-edit.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { UserAddComponent } from './user-add/user-add.component';

@NgModule({
  declarations: [LoginComponent, UserListComponent, UserEditComponent, PasswordChangeComponent, UserAddComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SecurityModule { }
